'use strict'

const _ = require('lodash')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')

function getEvent(syntheticEvent) {
    const basicEvent = {
        id: _.get(syntheticEvent, 'id') || this.props.id,
        timeStamp: this.props.now()
    }

    if (!syntheticEvent) {
        return basicEvent
    }

    basicEvent.timeStamp = syntheticEvent.timeStamp || basicEvent.timeStamp
    return _.defaults(basicEvent, syntheticEvent)
}

module.exports = {
    propTypes: {
        id: santaTypesDefinitions.Component.id,
        handleAction: santaTypesDefinitions.Behaviors.handleAction,
        compActions: santaTypesDefinitions.Component.compActions,
        now: santaTypesDefinitions.Utils.logging.performance.now
    },

    handleAction(actionName, syntheticEvent) {
        const actionToHandle = _.get(this.props.compActions, actionName)
        if (actionToHandle) {
            this.props.handleAction(actionToHandle, getEvent.call(this, syntheticEvent))
        }
    }
}